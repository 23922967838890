import * as React from 'react';

import { StateContainer } from 'infra-frontend/helpers/apollo';

// Interfaces
import { FieldError } from '../../../interfaces';

export interface State {
  fields: {
    userId: string;
    name: string;
    email: string;
    password: string;
  };
  showInputEmail: boolean;
  showPassword: boolean;
  showSaveDesigns: boolean;
  errors: FieldError[];
  userExists: boolean;
  [key: string]: any;
}

export default class RetrieveDesignStateContainer extends StateContainer<State> {
  initialState = {
    fields: {
      userId: '',
      name: '',
      email: '',
      password: '',
    },
    showInputEmail: true,
    showPassword: false,
    showSaveDesigns: false,
    errors: [],
    userExists: false,
  };

  // TODO: separate saving state from the page state
  shape = `
    {
      fields {
        userId
        name
        email
        password
      }
      showInputEmail
      showPassword
      showSaveDesigns
      errors {
        valid
        message
        field
      }
      userExists
    }
  `;

  updateField = (field: string, value: string) => {
    const fields = Object.assign({}, this.state.fields, { [field]: value });

    this.setState({ fields });
  };

  toggleBooleanField = (field: string) => {
    this.setState((state: State) => {
      return {
        [field]: !state[field],
      };
    });
  };

  setErrors = (errors: any) => {
    this.setState({ errors, signInError: '' });
  };

  setSignInError = (error: string) => {
    this.setState({ signInError: error });
  };
}
