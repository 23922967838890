const _ = {
  find: require('lodash/find'),
  pick: require('lodash/pick'),
};

import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Query, Mutation, MutationFn, withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { DataProxy } from 'apollo-cache';

import { Subscribe } from 'infra-frontend/helpers/apollo';
import { url, routes } from 'inkp-routes/public';
import { validateSignInParams, isUserRegistered } from 'inkp-user-sdk/user';
import { Mutation as UserMutationResults, MutationupdateUserArgs, User } from 'inkp-user-sdk/types.g';
import {
  getCurrentUser,
  QueryCurrentUser,
  QUERY_CURRENT_USER,
  updateCurrentUser,
  MutationSignIn,
  MUTATION_SIGN_IN,
  signOutUser,
  QUERY_USER_BY_EMAIL,
  MUTATION_UPDATE_USER,
  MutationUpdateUser,
} from '../../graphql/users';
import { QUERY_CART_BY_USER } from '../../util/Checkout';
import { findServiceError } from '../../util/apollo';

// Components
import Input from 'inkp-components/dist/Components/Input';
import PrimaryButton from 'inkp-components/dist/Components/PrimaryButton';

// Interfaces
import { FieldError } from '../../interfaces';

// Local Components
import SavedDesigns from '../../components/SavedDesigns';

// State Container
import RetrieveDesignStateContainer, { State } from './RetrieveDesignStateContainer';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';
import { ApolloError } from 'apollo-client';

class RetrieveDesign extends React.Component<WithApolloClient<RouteComponentProps>> {
  signInUpdate = () => (cache: DataProxy, { data }: { data: UserMutationResults }) => {
    updateCurrentUser(cache, data.signIn);
  };

  renderLoadingOrError = (loading: boolean, error: boolean) => {
    if (loading) {
      return <div>Loading</div>;
    }
    if (error) {
      return <div>Error</div>;
    }
    return null;
  };

  onSubmitEmail = ({
    state,
    fetchMore,
    toggleBooleanField,
    updateUser,
    userId,
  }: {
    state: State;
    fetchMore: any;
    toggleBooleanField: any;
    updateUser: MutationFn;
    userId: string;
  }) => {
    fetchMore({
      variables: { email: state.fields.email },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        const { userByEmail } = fetchMoreResult;
        if (!userByEmail) {
          updateUser({
            variables: {
              userData: {
                userId,
                email: state.fields.email,
              },
            },
            update: (cache: DataProxy, { data }: { data: UserMutationResults }) => {
              const { updateUser } = data;
              updateCurrentUser(cache, updateUser);
            },
          });

          toggleBooleanField('showInputEmail');
          return prev;
        }
        toggleBooleanField('showPassword');
        return Object.assign({}, prev, fetchMoreResult);
      },
    });
  };

  redirectToCartPage = (_cartId: string) => {
    this.props.history.push(routes.app.checkout.cart);
  };

  signInError = (setErrors: (errors: FieldError[]) => void, setSignInError: (error: string) => void) => (
    error: any
  ) => {
    const serviceError = findServiceError(error);
    if (serviceError && serviceError.extensions && serviceError.extensions.code === 'INVALID_CREDENTIALS') {
      return setErrors([{ valid: false, field: serviceError.data.data.field, message: serviceError.data.message }]);
    }
    setSignInError('Error encountered');
  };

  submit = (state: State, setErrors: (errors: FieldError[]) => void, signIn: MutationFn) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const validationResult = validateSignInParams(state.fields);
    if (validationResult.length > 0) {
      return setErrors(validationResult);
    }
    signIn({
      variables: { password: state.fields.password, email: state.fields.email },
    });
  };

  completeSignOut = () => () => {
    window.location.href = url('app', routes.app.base);
  };

  signOutUpdate = () => (cache: DataProxy, { data }: { data: { signOut: null } }) => {
    signOutUser(
      cache,
      {
        id: null,
        email: null,
        name: null,
        role: null,
        organization: null,
      },
      QUERY_CURRENT_USER
    );
  };

  componentDidMount() {
    const user = getCurrentUser(this.props.client.cache);
    if (isUserRegistered(user)) {
      this.props.history.push(`${routes.app.account.myAccount}?tab=Saved Designs`);
    }

    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    const user = getCurrentUser(this.props.client.cache);
    const savedDesignsProps: any = {
      onAddToCartClick: this.redirectToCartPage,
      onCartClick: this.redirectToCartPage,
      currentUser: user,
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>Inkpop - Retrieve Design</title>
        </Helmet>
        <div className="w-full flex flex-wrap md:w-container md:mx-auto md:py-0 md:mb-3" style={{ minHeight: '60vh' }}>
          <Subscribe to={[RetrieveDesignStateContainer]} namespace="RetrieveDesign">
            {({ state, updateField, toggleBooleanField, setSignInError, setErrors }) => {
              const { showInputEmail, showPassword, userExists } = state;
              if (userExists) {
                savedDesignsProps.userId = user.id;
              }

              savedDesignsProps.userEmail = state.fields.email;

              return (
                <MutationUpdateUser
                  mutation={MUTATION_UPDATE_USER}
                  onError={(error: ApolloError) => {
                    Sentry.captureMessage(
                      "Error while updating Current User's Email with the given email at Retrieve Design Page"
                    );
                    Sentry.captureException(error);
                  }}
                >
                  {(updateUser, { data: updateUserData, error: updateUserError, loading: updateUserLoading }) => {
                    if (showInputEmail) {
                      return (
                        <Query query={QUERY_CURRENT_USER}>
                          {({ data: { currentUser } }: any) => {
                            const email = state.fields.email;
                            return (
                              <React.Fragment>
                                {!isUserRegistered(currentUser) && (
                                  <Query query={QUERY_USER_BY_EMAIL} variables={{ email }}>
                                    {({ data, fetchMore }: any) => {
                                      if (data && data.userByEmail && !showPassword) {
                                        toggleBooleanField('showPassword');
                                      }
                                      return (
                                        <React.Fragment>
                                          <MutationSignIn
                                            mutation={MUTATION_SIGN_IN}
                                            update={this.signInUpdate()}
                                            onCompleted={() =>
                                              this.props.history.push(
                                                `${routes.app.account.myAccount}?tab=Saved Designs`
                                              )
                                            }
                                            onError={this.signInError(setErrors, setSignInError)}
                                            refetchQueries={[{ query: QUERY_CART_BY_USER }]}
                                          >
                                            {(signIn: MutationFn) => {
                                              const passwordError = _.find(state.errors, { field: 'password' });
                                              return (
                                                <div className="flex flex-col w-full px-1 md:w-container md:mx-auto">
                                                  <div className="w-full py-1p5 ta-left">
                                                    <Link to={routes.app.base} className="color-blue fs-md fw-bold">
                                                      <i className="d-ib mdi mdi-chevron-left color-blue fs-md" />
                                                      <h3 className="d-ib fs-md">Back to Home</h3>
                                                    </Link>
                                                  </div>
                                                  <div className="w-full mb-1p5 md:w-1/2 md:mx-auto">
                                                    <h1 className="fw-extra-bold color-navy fs-lg">
                                                      Retrieve Your Design
                                                    </h1>
                                                  </div>
                                                  <div className="w-full mb-1p5 md:w-1/2 md:mx-auto">
                                                    <Input
                                                      type="boxed"
                                                      inputType="email"
                                                      label="Email"
                                                      placeholder="Email"
                                                      error={false}
                                                      helper=""
                                                      icon=""
                                                      rightIcon=""
                                                      autoFocus={true}
                                                      clearable={false}
                                                      onFocus={() => {}}
                                                      onBlur={() => {}}
                                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        updateField('email', event.target.value.toLowerCase().trim());
                                                      }}
                                                      disabled={false}
                                                      removeInnerLabel={false}
                                                      value={email}
                                                    />
                                                  </div>
                                                  {showPassword ? (
                                                    <React.Fragment>
                                                      <div className="w-full mb-1p5 md:w-1/2 md:mx-auto">
                                                        <Input
                                                          type="boxed"
                                                          label="Password"
                                                          placeholder="Password"
                                                          inputType="password"
                                                          icon=""
                                                          rightIcon=""
                                                          autoFocus={true}
                                                          clearable={false}
                                                          onFocus={() => {}}
                                                          onBlur={() => {}}
                                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            updateField('password', event.target.value);
                                                          }}
                                                          disabled={false}
                                                          removeInnerLabel={false}
                                                          value={state.fields.password}
                                                          error={passwordError && !passwordError.valid}
                                                          helper={
                                                            passwordError ? passwordError.message : state.signInError
                                                          }
                                                          helperColor={
                                                            (passwordError && !passwordError.valid) || state.signInError
                                                              ? 'red'
                                                              : 'green'
                                                          }
                                                        />
                                                      </div>
                                                      <div
                                                        className="w-full ta-right md:w-1/2 md:mx-auto"
                                                        style={{ marginBottom: '112px' }}
                                                      >
                                                        <PrimaryButton onClick={this.submit(state, setErrors, signIn)}>
                                                          Retrieve Design
                                                        </PrimaryButton>
                                                      </div>
                                                    </React.Fragment>
                                                  ) : (
                                                    <React.Fragment>
                                                      <div
                                                        className="w-full ta-right md:w-1/2 md:mx-auto"
                                                        style={{ marginBottom: '112px' }}
                                                      >
                                                        <PrimaryButton
                                                          onClick={() =>
                                                            this.onSubmitEmail({
                                                              state,
                                                              fetchMore,
                                                              toggleBooleanField,
                                                              updateUser,
                                                              userId: currentUser.id,
                                                            })
                                                          }
                                                        >
                                                          Retrieve Design
                                                        </PrimaryButton>
                                                      </div>
                                                    </React.Fragment>
                                                  )}
                                                </div>
                                              );
                                            }}
                                          </MutationSignIn>
                                        </React.Fragment>
                                      );
                                    }}
                                  </Query>
                                )}
                              </React.Fragment>
                            );
                          }}
                        </Query>
                      );
                    }

                    return (
                      <React.Fragment>
                        <div className="w-full md:w-container md:mx-auto">
                          <div className="w-full md:flex md:items-center md:justify-between p-0">
                            <div>
                              <Link to={routes.app.base} className={'color-blue flex items-center'}>
                                <div className="pr-p5">
                                  <i className={'mdi mdi-chevron-left p-0 fs-icon-1p5'} />
                                </div>
                                <div className="fs-md">Back to Home</div>
                              </Link>
                            </div>
                            <div>
                              <span className="color-navy pr-p5">{savedDesignsProps.userEmail}</span>
                              <Link to={routes.app.account.register} className="cursor-pointer">
                                <PrimaryButton color="secondary" disabled={true}>
                                  Create Account
                                </PrimaryButton>
                              </Link>
                            </div>
                          </div>
                          <div className="w-full">
                            <SavedDesigns {...savedDesignsProps} />
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </MutationUpdateUser>
              );
            }}
          </Subscribe>
        </div>
      </React.Fragment>
    );
  }
}

export default withApollo(RetrieveDesign);
